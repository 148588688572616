import { Button } from "primereact/button";
import React, { useState } from "react";
import Php from "../../../Backend/Php";
import NumberFormatIn from "../../Common/NumberFormatIn";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import {
  BlackText,
  BlueText,
  BoldText,
  ButtonGrid,
  CreateClientButton,
  FlexContainer,
  FlexItem,
  GrayText,
  GreenText,
  Wrapper,
} from "../../Users/Overview/Styled";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import MultiplierModal from "../Modal/MultiplierModal";
import { ProgressSpinner } from "primereact/progressspinner";
import BrokerageModal from "../Modal/BrokerageModal";
import moment from "moment";

export const FlexRow = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: flex-start; // Adjust this as needed
  gap: 10px; // Adjust the gap between items as needed
  margin-bottom: 10px; // Adds some space between the rows
`;

export const BlueTextLocal = styled.span`
  color: ${({ color }) => color}; // Apply color dynamically
  flex-grow: 1;
  position: relative;
  flex-basis: calc(20% - 10px); // Occupies 20% of the row minus the gap
  text-align: left; // Ensure text within BlueText is left-aligned
  /* Ensure wrapping or any additional styles as needed */
`;

const php = new Php();

export default function Overview() {
  const [loading, setLoading] = React.useState(false);
  const [buttonsData, setButtonsData] = React.useState([]);
  const [clientData, setClientData] = React.useState([]);
  const { addNotification } = useNotification();

  React.useEffect(() => {
    load_profile();
  }, []);

  const load_profile = () => {
    if (!loading) {
      setLoading(true);
      let data2 = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        s_id: localStorage.getItem("supersId"),
      };

      php
        .load_profile(data2)
        .then((r) => {
          if (r.error == "False") {
            setClientData(r.profile[0]);
            setButtonsData(r.buttons_data);
          } else if (r.error === "logout") {
            localStorage.clear();
            window.location = "/";
          } else {
            addNotification(r.message, "error");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return loading ? (
    <ProgressSpinner
      style={{ width: "50px", height: "50px" }}
      strokeWidth="8"
      fill="var(--surface-ground)"
      animationDuration=".5s"
    />
  ) : (
    <>
      <Wrapper>
        <FlexContainer>
          <FlexItem>
            <GrayText>Super Alias | Name</GrayText>
            <BoldText>
              <span style={{ color: "gray" }}>
                {clientData?.alias == null || clientData?.alias == ""
                  ? ""
                  : clientData?.alias?.toUpperCase() + " | "}
              </span>
              {clientData?.name?.toUpperCase()}
            </BoldText>
          </FlexItem>
          <FlexItem>
            <GrayText>Super Username</GrayText>
            <BlueText>{clientData?.username?.toUpperCase()}</BlueText>
          </FlexItem>
          <FlexItem>
            <GrayText>Super Server</GrayText>
            <BlueText>{clientData?.server}</BlueText>
          </FlexItem>
        </FlexContainer>

        <FlexContainer>
          <FlexItem>
            <GrayText>Masters</GrayText>
            <BlueText>{clientData?.masters}</BlueText>
          </FlexItem>
          <FlexItem>
            <GrayText>Users</GrayText>
            <BlueText>{clientData?.users}</BlueText>
          </FlexItem>
          <FlexItem>
            <GrayText>Brokers</GrayText>
            {clientData?.brokers}
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <GrayText>Created On</GrayText>
            <BoldText>
              {moment(clientData?.date_created).format("DD-MMM-YY")}
            </BoldText>
          </FlexItem>
        </FlexContainer>
      </Wrapper>

      <ButtonGrid>
        <CreateClientButton
          active={clientData?.status == 1 ? true : false}
          color={"#22c55e"}
        >
          {"ACTIVE"}
        </CreateClientButton>
        {buttonsData.map((button, index) => (
          <CreateClientButton
            key={index}
            active={button.active}
            color={button.color}
          >
            {button.text}
          </CreateClientButton>
        ))}
      </ButtonGrid>
    </>
  );
}
