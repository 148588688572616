import { Button } from "primereact/button";
import React, { useState } from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import LiveSummaryModal from "./Modal/LiveSummaryModal";
import MultiplierModal from "./Modal/MultiplierModal";
import {
  BlackText,
  BlueText,
  BoldText,
  ButtonGrid,
  CreateClientButton,
  FlexContainer,
  FlexItem,
  GrayText,
  GreenText,
  Wrapper,
} from "./Styled";
import NumberFormatIn from "../../Common/NumberFormatIn";
import LimitModal from "./Modal/LimitModal";
import DepositModal from "./Modal/DepositModal";

const php = new Php();

export default function Overview(props) {
  const [loading, setLoading] = React.useState(false);
  const [buttonsData, setButtonsData] = React.useState([]);
  const [clientData, setClientData] = React.useState([]);
  const { addNotification } = useNotification();
  const [timeDiff, setTimeDiff] = useState(0);

  React.useEffect(() => {
    load_users_overview();
  }, [props.data.id]);

  const load_users_overview = () => {
    if (!loading) {
      setLoading(true);
      let data2 = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        s_id: localStorage.getItem("supersId"),
        c_id: props.data.id,
      };

      php.load_users_overview(data2).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          setClientData(r.users_overview[0]);
          setButtonsData(r.buttons_data);
        } else if (r.error === "logout") {
          localStorage.clear();
          window.location = "/";
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  function handleButtonClick(key) {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        s_id: localStorage.getItem("supersId"),
        c_id: clientData.id,
        button_key: key,
      };

      php.change_users_setting(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          load_users_overview();
          if (r.on_off == "0") {
            addNotification(r.message, "error");
          } else {
            addNotification(r.message, "success");
          }
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  }

  function handleUsersMultiplier(fut, mcx, fo) {
    if (
      isNaN(parseFloat(fut)) ||
      isNaN(parseFloat(mcx)) ||
      isNaN(parseFloat(fo))
    ) {
      window.alert("Only Number Are Allowed");
    } else if (
      parseFloat(fut) <= 0 ||
      parseFloat(mcx) <= 0 ||
      parseFloat(fo) <= 0
    ) {
      window.alert("Enter Number Greater than 0");
    } else {
      if (!loading) {
        setLoading(true);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          s_id: localStorage.getItem("supersId"),
          c_id: clientData.id,
          fut_multiplier: fut,
          mcx_multiplier: mcx,
          fo_multiplier: fo,
        };

        php.change_users_multiplier(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            load_users_overview();
            setVisible(false);
            addNotification(r.message, "success");
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  }

  const handleStatusButtonClick = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        s_id: localStorage.getItem("supersId"),
        c_id: clientData.id,
      };

      php.change_users_status(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          load_users_overview();
          if (r.on_off == "0") {
            addNotification(r.message, "error");
          } else {
            addNotification(r.message, "success");
          }
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const handleLockButtonClick = () => {
    if (clientData?.block_minute == "") {
      var selection = parseInt(window.prompt("Enter Minutes To Lock"));
      if (
        isNaN(parseFloat(selection)) ||
        parseFloat(selection) == null ||
        parseFloat(selection) == "" ||
        parseFloat(selection) == undefined
      ) {
        window.alert("Only Number Are Allowed");
      } else {
        if (!loading) {
          setLoading(true);
          let data = {
            sr: localStorage.getItem("server"),
            jwt: localStorage.getItem("token"),
            s_id: localStorage.getItem("supersId"),
            c_id: clientData.id,
            lock_time: selection,
          };

          php.add_users_lock(data).then((r) => {
            setLoading(false);
            if (r.error === "False") {
              load_users_overview();
              if (r.on_off == "0") {
                addNotification(r.message, "error");
              } else {
                addNotification(r.message, "success");
              }
            } else {
              addNotification(r.message, "error");
            }
          });
        }
      }
    } else {
      let check = window.confirm(
        "Are you sure want to unlock trades for client ?"
      );
      if (check) {
        if (!loading) {
          setLoading(true);
          let data = {
            sr: localStorage.getItem("server"),
            jwt: localStorage.getItem("token"),
            s_id: localStorage.getItem("supersId"),
            c_id: clientData.id,
          };

          php.cancel_users_lock(data).then((r) => {
            setLoading(false);
            if (r.error === "False") {
              load_users_overview();
              if (r.on_off == "0") {
                addNotification(r.message, "error");
              } else {
                addNotification(r.message, "success");
              }
            } else {
              addNotification(r.message, "error");
            }
          });
        }
      }
    }
  };

  const handleFatakButtonClick = () => {
    if (clientData?.fatak <= 0) {
      var selection = parseInt(
        window.prompt("Enter Limit For Boundary To Trigger")
      );
      if (
        isNaN(parseFloat(selection)) ||
        parseFloat(selection) == null ||
        parseFloat(selection) == "" ||
        parseFloat(selection) == undefined
      ) {
        window.alert("Only Number Are Allowed");
      } else {
        if (!loading) {
          setLoading(true);
          let data = {
            sr: localStorage.getItem("server"),
            jwt: localStorage.getItem("token"),
            s_id: localStorage.getItem("supersId"),
            c_id: clientData.id,
            fatak: selection,
          };

          php.add_users_fatak(data).then((r) => {
            setLoading(false);
            if (r.error === "False") {
              load_users_overview();
              if (r.on_off == "0") {
                addNotification(r.message, "error");
              } else {
                addNotification(r.message, "success");
              }
            } else {
              addNotification(r.message, "error");
            }
          });
        }
      }
    } else {
      let check = window.confirm(
        "Are you sure want to Reset client Boundary ?"
      );
      if (check) {
        if (!loading) {
          setLoading(true);
          let data = {
            sr: localStorage.getItem("server"),
            jwt: localStorage.getItem("token"),
            s_id: localStorage.getItem("supersId"),
            c_id: clientData.id,
          };

          php.cancel_users_fatak(data).then((r) => {
            setLoading(false);
            if (r.error === "False") {
              load_users_overview();
              if (r.on_off == "0") {
                addNotification(r.message, "error");
              } else {
                addNotification(r.message, "success");
              }
            } else {
              addNotification(r.message, "error");
            }
          });
        }
      }
    }
  };

  const [visible, setVisible] = useState(false);
  const [visibleDepositModal, setVisibleDepositModal] = useState(false);
  const [visibleLimitModal, setVisibleLimitModal] = useState(false);
  const [visibleLiveSummaryModal, setVisibleLiveSummaryModal] = useState(false);
  const [position, setPosition] = useState("top");

  const show = (position) => {
    setPosition("top");
    setVisible(true);
  };

  const showDepositModal = (position) => {
    setPosition("top");
    setVisibleDepositModal(true);
  };

  const showLimitModal = (position) => {
    setPosition("top");
    setVisibleLimitModal(true);
  };

  const showLiveSummaryModal = (position) => {
    setPosition("top");
    setVisibleLiveSummaryModal(true);
  };

  return (
    <>
      <Wrapper>
        <FlexContainer>
          <FlexItem>
            <GrayText>Client Name</GrayText>
            <BoldText>
              <span style={{ color: "gray" }}>
                {clientData?.alias == null || clientData?.alias == ""
                  ? ""
                  : clientData?.alias + " | "}
              </span>
              {clientData?.name}
            </BoldText>
          </FlexItem>
          <FlexItem>
            <GrayText>Client Username</GrayText>
            <BlueText>{clientData?.username}</BlueText>
          </FlexItem>
          <FlexItem>
            <GrayText>Weekly Profit Loss</GrayText>
            <GreenText
              style={{
                color:
                  clientData?.weekly_pl >= 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn value={clientData?.weekly_pl} />
            </GreenText>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <GrayText>Balance</GrayText>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <BlackText>
                <NumberFormatIn value={clientData?.deposit} />
              </BlackText>
              <Button
                label="Edit"
                outlined
                aria-label="Filter"
                size="small"
                style={{
                  padding: 0,
                  height: "25px",
                  width: "40px",
                }}
                onClick={showDepositModal}
              />
            </div>
          </FlexItem>
          <FlexItem>
            <GrayText>Total Deposit</GrayText>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <BoldText>
                <NumberFormatIn
                  value={parseFloat(clientData?.total_limit).toFixed(0)}
                />
              </BoldText>
              <Button
                label="Edit"
                outlined
                aria-label="Filter"
                size="small"
                style={{
                  padding: 0,
                  height: "25px",
                  width: "40px",
                }}
                onClick={showLimitModal}
              />
            </div>
          </FlexItem>
          <FlexItem>
            <GrayText>Client Brokerages: </GrayText>
            <BoldText>
              {"Fut : " +
                parseFloat(clientData?.fut_brokerage) +
                " | Mcx : " +
                parseFloat(clientData?.mcx_brokerage) +
                " | Option : " +
                parseFloat(clientData?.fo_brokerage) +
                ""}
            </BoldText>
            <GrayText style={{ marginTop: 5 }}>Client Server | Master</GrayText>
            <BoldText>
              {clientData?.server + " | " + clientData?.master}
            </BoldText>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <GrayText>Multiplier</GrayText>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <BoldText>Fut :{clientData?.fut_multiplier} x</BoldText>
                <BoldText> Mcx : {clientData?.mcx_multiplier}x </BoldText>
                <BoldText> Option : {clientData?.fo_multiplier}x</BoldText>
              </div>
              <Button
                label="Edit"
                outlined
                aria-label="Filter"
                size="small"
                style={{
                  padding: 0,
                  height: "25px",
                  width: "40px",
                }}
                onClick={() => show()}
              />
            </div>
          </FlexItem>
          <FlexItem>
            <GrayText>Trade Limit</GrayText>
            <BoldText>
              <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <BoldText>
                    Fut :{" "}
                    <NumberFormatIn
                      value={
                        parseFloat(clientData?.total_limit).toFixed(0) *
                        parseFloat(clientData?.fut_multiplier).toFixed(0)
                      }
                    />
                  </BoldText>
                  <BoldText>
                    Mcx :{" "}
                    <NumberFormatIn
                      value={
                        parseFloat(clientData?.total_limit).toFixed(0) *
                        parseFloat(clientData?.mcx_multiplier).toFixed(0)
                      }
                    />
                  </BoldText>
                  <BoldText>
                    Option :{" "}
                    <NumberFormatIn
                      value={
                        parseFloat(clientData?.total_limit).toFixed(0) *
                        parseFloat(clientData?.fo_multiplier).toFixed(0)
                      }
                    />
                  </BoldText>
                </div>
              </div>
            </BoldText>
          </FlexItem>
          <FlexItem>
            <GrayText>
              Broker:{" "}
              <span style={{ color: "black", fontWeight: "bold" }}>
                {clientData.broker_id > 0 ? clientData.broker_username : "----"}
              </span>
              {" | "}
              Sharing:{" "}
              <span style={{ color: "black", fontWeight: "bold" }}>
                {clientData.broker_id > 0 ? clientData.sharing : "0"}%
              </span>
            </GrayText>
            <GrayText></GrayText>
            <BoldText>
              {clientData.broker_id > 0
                ? "Fut : " +
                  clientData.fut_percent +
                  "% | Mcx : " +
                  clientData.mcx_percent +
                  "% | Option : " +
                  clientData.fo_percent +
                  "%"
                : "----"}
            </BoldText>
          </FlexItem>
        </FlexContainer>
      </Wrapper>

      <ButtonGrid>
        <CreateClientButton
          active={true}
          color={NewTheme.MainColor}
          onClick={() => showLiveSummaryModal()}
        >
          {"LIVE REPORT"}
        </CreateClientButton>
        <CreateClientButton
          active={clientData?.status == 1 ? true : false}
          color={"#22c55e"}
          onClick={() => handleStatusButtonClick()}
        >
          {"ACTIVE"}
        </CreateClientButton>
        <CreateClientButton
          active={true}
          color={"#c522b7b8"}
          onClick={() => handleLockButtonClick()}
        >
          {"LOCK " +
            (clientData?.block_minute > 0
              ? "(" + clientData?.block_minute + "m)"
              : "")}
        </CreateClientButton>
        <CreateClientButton
          active={true}
          color={"#229ec5"}
          onClick={() => handleFatakButtonClick()}
        >
          {"BOUNDARY " +
            (clientData?.fatak != "" ? "(" + clientData?.fatak + ")" : "")}
        </CreateClientButton>
        {buttonsData.map((button, index) => (
          <CreateClientButton
            key={index}
            active={button.active}
            color={button.color}
            onClick={() => handleButtonClick(button.id)} // Add the onClick handler
          >
            {button.text}
          </CreateClientButton>
        ))}
      </ButtonGrid>

      {visibleDepositModal ? (
        <DepositModal
          key={clientData?.id}
          setVisible={() => setVisibleDepositModal(false)}
          position={position}
          id={clientData?.id}
          visible={visibleDepositModal}
          reload={() => load_users_overview()}
        />
      ) : null}

      {visibleLimitModal ? (
        <LimitModal
          key={clientData?.id}
          setVisible={() => setVisibleLimitModal(false)}
          position={position}
          id={clientData?.id}
          visible={visibleLimitModal}
          reload={() => load_users_overview()}
        />
      ) : null}

      {visible ? (
        <MultiplierModal
          key={clientData?.id}
          setVisible={() => setVisible(false)}
          position={position}
          visible={visible}
          max_multiplier={clientData?.max_multiplier}
          min_multiplier={clientData?.min_multiplier}
          fut={clientData?.fut_multiplier}
          mcx={clientData?.mcx_multiplier}
          fo={clientData?.fo_multiplier}
          handleUsersMultiplier={(fut, mcx, fo) =>
            handleUsersMultiplier(fut, mcx, fo)
          }
        />
      ) : null}

      {visibleLiveSummaryModal ? (
        <LiveSummaryModal
          key={clientData?.id}
          usersId={clientData?.id}
          setVisible={() => setVisibleLiveSummaryModal(false)}
          position={position}
          visible={visibleLiveSummaryModal}
          //
          fut_multiplier={clientData?.fut_multiplier}
          mcx_multiplier={clientData?.mcx_multiplier}
          fo_multiplier={clientData?.fo_multiplier}
          //
          deposit={clientData?.deposit}
          blocked_limit={clientData?.blocked_limit}
          weekly_pl={clientData?.weekly_pl}
          clientUsername={clientData?.username}
        />
      ) : null}
    </>
  );
}
